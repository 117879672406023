import React from 'react';
import isEmpty from 'lodash/isEmpty';
import isNull from 'lodash/isNull';
import get from 'lodash/get';
import { CORE_EMAIL_REGEX } from 'common/js_utils/accounts';
import Alerts from './components/Alerts';
import { Email, RegexPattern, Alert, SsoConnection } from './types';
import url from 'url';

/**
 * Unless 'strict' is true, anything with an @ followed by text is considered "valid"
 * This is so for SSO (federated) email addresses you can just type "@whatever"
 * and be sent to the right login system (i.e. just typing "@socrata" will log you
 * in with our login system)
 */
export const isValidEmail = (email: Email, strict = false): boolean => {
  if (strict === true) {
    return new RegExp(CORE_EMAIL_REGEX, 'i').test(email);
  } else {
    return new RegExp('@.+$', 'i').test(email);
  }
};

/**
 * Returns true if the given email is an attempt to "spoof" a user.
 * That is, it is of the form "user_email@email.com superadmin@socrata.com"
 */
export const isSpoofing = (email: Email): boolean => {
  if (isEmpty(email)) {
    return false;
  }

  const split = email.split(' ');
  return split.length === 2 && isValidEmail(split[0], true) && isValidEmail(split[1], true);
};

/**
 * This finds the first SSO connection in the list SSO connections that matches the given email.
 * If no such forced connection is found, undefined is returned instead.
 */
export const findForcedConnection = (email: Email, ssoConnections: SsoConnection[]): SsoConnection | null => {
  if (!ssoConnections) return null;

  for (const connection of ssoConnections) {
    for (const regex of connection.emailPatterns) {
      const compiledRegex = new RegExp(`^${regex}$`, 'i');
      if (compiledRegex.test(email)) {
        return connection;
      }
    }
  }

  return null;
};

/**
 * Finds a connection either by the email domain or by the configured forced connection regex
 */
export const findConnection = (
  email: Email,
  forcedConnections: SsoConnection[],
  platformAdminConnection: SsoConnection | null
): SsoConnection | null => {
  const forcedConnection = findForcedConnection(email, forcedConnections);
  const adminRegex = platformAdminConnection?.emailPatterns[0];

  if (forcedConnection?.applyEmailPattern) {
    return forcedConnection;
  } else if (adminRegex && new RegExp(adminRegex, 'i').test(email)) {
    return platformAdminConnection;
  }

  return null;
};

/**
 * Render all the alerts
 */
export const renderAlerts = (alerts: [Alert['level'], Alert['message']]) => {
  if (isEmpty(alerts)) {
    return null;
  }

  const messages = alerts.map((flash): Alert | null => {
    if (isNull(flash)) {
      return null;
    }

    return {
      level: flash[0],
      message: flash[1]
    } as Alert;
  });

  return <Alerts alerts={messages} />;
};

/**
 * Generates a URI to redirect to core to initiate authentication via Auth0
 * @param {string} baseDomainUri URI of the current domain
 * @param ssoConnection connection to use
 */
export const createCoreAuthorizeUri = (baseDomainUri: string, ssoConnection: SsoConnection): string => {
  const parsedUrlParams = url.parse(location.search, true);
  const returnTo = get(parsedUrlParams, 'query.return_to', null);

  if (ssoConnection.type === 'okta' || ssoConnection.type === 'okta-users') {
    return buildOktaAuthorizeUri(baseDomainUri, ssoConnection, returnTo);
  } else if (ssoConnection.type === 'tid_gateway') {
    return buildTidGatewayAuthorizeUri(baseDomainUri, ssoConnection, returnTo);
  } else {
    return buildAuth0AuthorizeUri(baseDomainUri, ssoConnection.id, returnTo);
  }
};

const buildAuth0AuthorizeUri = (
  baseDomainUri: string,
  connectionName: string,
  returnTo: string | null
): string => {
  let redirectUri = `${baseDomainUri}/api/auth0/callback`;
  if (returnTo != null) {
    redirectUri += `?returnTo=${returnTo}`;
  }

  const encodedRedirectUri = encodeURIComponent(redirectUri);
  let coreUri = `${baseDomainUri}/api/auth0/initiate`;
  coreUri += `?redirectUri=${encodedRedirectUri}`;
  coreUri += `&connection=${connectionName}`;

  return coreUri;
};

const buildOktaAuthorizeUri = (
  domainBase: string,
  connection: SsoConnection,
  returnTo: string | null
): string => {
  let uri = `${domainBase}/api/okta/initiate`;

  const queryParams: string[] = [];

  if (connection.type === 'okta') {
    queryParams.push(`idp=${connection.id}`);
  }

  if (returnTo) {
    const encodedReturnTo = encodeURIComponent(returnTo);
    queryParams.push(`returnTo=${encodedReturnTo}`);
  }

  if (queryParams.length) {
    uri += '?' + queryParams.join('&');
  }

  return uri;
};

const buildTidGatewayAuthorizeUri = (
  domainBase: string,
  connection: SsoConnection,
  returnTo: string | null
): string => {
  let uri = `${domainBase}/api/tid_gateway/initiate`;

  const queryParams: string[] = [];
  queryParams.push(`crmId=${connection.id}`);

  if (returnTo) {
    const encodedReturnTo = encodeURIComponent(returnTo);
    queryParams.push(`returnTo=${encodedReturnTo}`);
  }

  if (queryParams.length) {
    uri += '?' + queryParams.join('&');
  }

  return uri;
};
